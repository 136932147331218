<template>
  <div class="w-100 h-100">
    <section
      id="chatbot-body"
      ref="refChatLogPS"
      class="ps-container position-relative scroll-area ps chatbot"
      style="background-repeat:no-repeat; background-size: 100%;"
      :style="{ 'background-image': `url(${require(`@/assets/images/chatbot/${activeImage}`)})`}"
    >
      <div class="position-absolute setting-icon"
           draggable="false"
           style="right:10px; top:10px; display:none;"
      >
        <feather-icon icon="SettingsIcon"
                      size="21"
                      class="text-primary cursor-pointer"
                      @click.stop="openChatbotSetting"
        />
      </div>
      <div class="ps__rail-x"
           style="left: 0px; bottom: 0px"
      >
        <div class="ps__thumb-x"
             tabindex="0"
             style="left: 0px; width: 0px"
        />
      </div>
      <div class="ps__rail-y"
           style="top: 0px; right: 0px"
      >
        <div class="ps__thumb-y"
             tabindex="0"
             style="top: 0px; height: 0px"
        />
      </div>
    </section>
    <chat-footer :is-processing="isProcessing"
                 :is-playing="isPlaying"
                 @sendMessage="sendMessage"
    />
    <b-modal id="bot-setting-modal"
             title="Chatbot Settings"
             hide-footer
    >
      <bot-setting />
    </b-modal>
  </div>
</template>
<script>
// import axios from 'axios'
// import Preloader from '@/views/common/components/Preloader.vue'
// import { BButton } from 'bootstrap-vue'
import {
  IDLE, SPEAKING, HAPPY_IDLE, HAPPY_SPEAKING, BOT_OBJ,
} from '@/const/chatbot'
import useJwt from '@/auth/jwt/useJwt'
import { checkSettings } from '@/utils/visibilitySettings'
import PlayBase64Audio from '@/utils/base64-audio-player'
import ChatFooter from './ChatFooter.vue'
import BotSetting from './BotSetting.vue'
import getLabTemplate from './lab-template'

const synth = window.speechSynthesis
export default {
  components: {
    ChatFooter,
    BotSetting,
    // BButton,
    // Preloader,
  },
  props: {
    topics: {
      type: String,
      required: true,
    },
    pageMetaInfo: {
      type: Array,
      default: () => [],
    },
    problemId: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      self: this.AUTH_USER(),
      isProcessing: false,
      speechSynthesisUtterance: null,
      isPlaying: false,
      templates: [],
      messages: [
      ],
    }
  },
  computed: {
    activeImage() {
      const schoolSetting = this.$store.state.appConfig?.schoolInfo?.user_meta
      const distSetting = this.$store.state.appConfig?.districtInfo?.user_meta

      const robotSetting = checkSettings('robot_style', schoolSetting, distSetting)?.value ?? 'serious'
      if (robotSetting === 'serious') {
        return this.isPlaying ? SPEAKING[this.gender] : IDLE[this.gender]
      }
      return this.isPlaying ? HAPPY_SPEAKING[this.gender] : HAPPY_IDLE[this.gender]
    },
    gender() {
      return this.$store.getters['appConfig/currentChatbotCharacter']
    },

  },
  watch: {
    problemId() {
      this.messages = []
    },
  },
  mounted() {
    useJwt.getStudentBotChatHistory({
      params: {
        key: this.topics,
        gender: this.gender,
      },
    }).then(response => {
      this.templates = response.data.data.templates
      setTimeout(() => {
        this.initializeSpeech()
        this.introduce()
      }, 1000)
    })

    window.onbeforeunload = () => {
      this.stop()
    }
    this.$root.$off('CHAT_BOT_AFTER_FEEDBACK')
    this.$root.$on('CHAT_BOT_AFTER_FEEDBACK', message => {
      this.getBase64FromTextAndPlay(message)
    })
  },
  beforeDestroy() {
    this.stop()
  },
  methods: {
    getBase64FromTextAndPlay(message) {
      useJwt.convertTextToBase64({
        text: message,
        gender: this.gender?.includes('female') ? 'female' : 'male',
      }).then(response => {
        this.play(response.data.audio_file)
      })
    },
    introduce() {
      const auth = this.AUTH_USER()
      const studentName = auth.nickname || auth.firstname
      const message = `Hi ${studentName} can you tell me about ${this.topics}?`
      this.messages.push({
        from: 'bot',
        message,
        avatar: this.self.avatar,
      })
      this.getBase64FromTextAndPlay(message)
    },
    initializeSpeech() {
      this.stop()
      const speechSynthesisUtterance = new SpeechSynthesisUtterance()
      speechSynthesisUtterance.lang = 'en-US'
      this.speechSynthesisUtterance = speechSynthesisUtterance
      this.speechSynthesisUtterance.addEventListener('end', () => {
        this.isPlaying = false
      })
      this.speechSynthesisUtterance.addEventListener('ended', () => {
        this.isPlaying = false
      })
      setTimeout(() => {
        this.isPlaying = false
      }, 2500)
    },
    pause() {
      if (synth?.speaking) {
        synth.pause()
        this.isPlaying = false
      }
    },
    stop() {
      if (synth?.speaking) {
        synth.cancel()
      }
    },
    play(base64) {
      this.isPlaying = true
      PlayBase64Audio(base64, () => {
        this.isPlaying = false
      })
    },
    chatParameter(msg) {
      const chatHistory = this.messages.map(item => {
        if (item.from === 'bot') {
          return {
            chatbot: item.message,
          }
        }
        return {
          human: item.message,
        }
      })

      return {
        bot: 'Chatbot',
        chat_history: chatHistory,
        human_input: msg,
        prompt: getLabTemplate(this),
        temperature: this.templates.find(i => i.type === this.$store.state.studentLab.activeViewOfLab)?.temperature || null,
      }
    },
    scrollToBottomInChatLog() {
      if (this.$refs.refChatLogPS) {
        const scrollEl = this.$refs.refChatLogPS
        scrollEl.scrollTop = scrollEl.scrollHeight

        setTimeout(() => {
          if (scrollEl.scrollTop !== scrollEl.scrollHeight) scrollEl.scrollTop = scrollEl.scrollHeight
        }, 500)
      }
    },
    sendMessage(msg) {
      this.messages.push({
        from: this.self.id,
        message: msg,
        avatar: this.self.avatar,
      })
      // this.scrollToBottomInChatLog()
      this.isProcessing = true
      const chatParameter = this.chatParameter(msg)
      console.log(chatParameter.temperature)
      useJwt.saveStudentBotChatHistory({
        chat_history: chatParameter.chat_history,
        prompt: chatParameter.prompt,
        key: this.topics,
        voice: this.gender.includes('female') ? 'female' : 'male',
        temperature: chatParameter.temperature,
      }).then(response => {
        this.messages.push({
          from: 'bot',
          message: response.data.data.response,
          avatar: BOT_OBJ.avatar,
        })
        this.play(response.data.data.audio_file)
      }).finally(() => {
        this.isProcessing = false
      })
    },
    openChatbotSetting() {
      this.$bvModal.show('bot-setting-modal')
    },
  },
}
</script>
<style lang="scss">
  .chatbot {
    height: 150px;
    width: 150px;
    margin:auto;

    &:hover{
      .setting-icon {
        display: block !important;
      }
    }
    /* overflow: scroll !important; */
  }
</style>
